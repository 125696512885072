import { FileTypeErrorMessage } from './FileTypeErrorMessage';

// =============================================================
// Errors

export const getSizeErrorMessage = (maxSize = 10) =>
  `Please upload a file smaller than ${maxSize}MB.`;

export const ERRORS = {
  TYPE: FileTypeErrorMessage,
  SIZE: getSizeErrorMessage,
  MAX_LENGTH: 'Too many files',
  UNIQUENESS: 'Already exists',
  INTERNAL: 'Unable to upload file',
};

import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as Sentry from '@sentry/nextjs';

import {
  DragEvent,
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { useScreenSize } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Icon, IconTypeProp } from '../Icon';
import Spinner from '../Spinner';
import { Paragraph } from '../Typography';
import { ERRORS } from './constants';
import * as helpers from './helpers';
import {
  InstanceInput,
  InstanceKey,
  InstanceMap,
  InstanceResultMap,
  MultiUploadSubmitResult,
  UploadFile,
  UploadOptions,
} from './types';

function instanceArrayToInstanceMap(
  instancesInput: InstanceInput[]
): InstanceMap {
  return instancesInput.reduce((obj, instance) => {
    return {
      ...obj,
      [instance.title]: {
        ...instance,
        files: [],
      },
    };
  }, {} as InstanceMap);
}

export const useMultiUpload = (options: UploadOptions) => {
  const [instances, setInstances] = useState<InstanceMap>(
    options?.instances ? instanceArrayToInstanceMap(options?.instances) : {}
  );

  const _options = { ...options };
  const instancesKeys = Object.keys(instances);

  const isLoading = instancesKeys.some((key) =>
    instances[key].files.some((f) => f.status === 'uploading')
  );

  const hasError = instancesKeys.some((key) =>
    instances[key].files.some((f) => f.status === 'error')
  );

  const filesCount = instancesKeys.reduce((count, key) => {
    const instance = instances[key];
    return count + instance.files.length;
  }, 0);

  const addInstances = (newInstancesArr: InstanceInput[]) => {
    setInstances((prev) => {
      const newInstancesMap = instanceArrayToInstanceMap(newInstancesArr);
      return { ...prev, ...newInstancesMap };
    });
  };

  const removeInstances = (instancesTitles: string[]) => {
    setInstances((prev) => {
      instancesTitles.forEach((instanceTitle) => {
        delete prev[instanceTitle];
      });
      return prev;
    });
  };

  const addFile = (file: File, instanceKey: InstanceKey) => {
    setInstances((prev) => {
      const foundInstance = prev[instanceKey];
      const timestamp = Date.now();
      const errors = helpers.validateFile(file, foundInstance.files, _options);
      const status = errors.length > 0 ? 'error' : 'idle';

      return {
        ...prev,
        [instanceKey]: {
          ...foundInstance,
          files: [
            ...foundInstance.files,
            {
              id: `${file.name}-${timestamp}`,
              file,
              status,
              errors,
              progress: 0,
              key: null,
            },
          ],
        },
      };
    });
  };

  const removeFile = (fileId: string, instanceKey: InstanceKey) => {
    setInstances((prev) => {
      const foundInstance = prev[instanceKey];

      return {
        ...prev,
        [instanceKey]: {
          ...foundInstance,
          files: foundInstance.files.filter((f) => f.id !== fileId),
        },
      };
    });
  };

  const updateFile = (
    fileId: string,
    payload: Partial<UploadFile>,
    instanceKey: InstanceKey
  ) => {
    setInstances((prev) => {
      const foundInstance = prev[instanceKey];

      return {
        ...prev,
        [instanceKey]: {
          ...foundInstance,
          files: foundInstance.files.map((f) => {
            if (f.id === fileId) {
              return { ...f, ...payload };
            }
            return f;
          }),
        },
      };
    });
  };

  const _uploadToS3 = async (files: UploadFile[], instanceKey: InstanceKey) => {
    const successful: UploadFile[] = [];
    const failed: UploadFile[] = [];

    for await (const file of files) {
      if (file.errors.length > 0) continue;

      updateFile(file.id, { status: 'uploading' }, instanceKey);

      try {
        const { key } = await helpers.uploadToS3(file, {
          onFileProgress: (progress) => {
            updateFile(file.id, { progress }, instanceKey);
          },
          uploaded_by: _options?.uploaded_by,
          fileType: _options?.fileType,
          source: _options.source,
          leadId: _options?.leadId,
          token: _options?.token,
          documentType: _options?.documentType,
        });

        updateFile(file.id, { status: 'success' }, instanceKey);
        successful.push({ ...file, key });
      } catch (error) {
        Sentry.captureException(error);

        updateFile(
          file.id,
          { errors: [...file.errors, ERRORS.INTERNAL] },
          instanceKey
        );

        failed.push(file);
        toast(
          `Upload "${instanceKey}" incomplete. Please check your files and re-upload`,
          {
            type: 'error',
            position: 'top-right',
            autoClose: 3000,
          }
        );
      }
    }

    return { successful, failed };
  };

  const _processAndCreate = async (files: UploadFile[]) => {
    try {
      await helpers.processAndCreate(files, {
        leadId: _options?.leadId,
        fileCategory: _options?.fileCategory,
        fileType: _options?.fileType,
        token: _options?.token,
      });

      return { success: true, files };
    } catch (error) {
      Sentry.captureException(error);
      toast('There was an error creating the file', {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
      });
      return { success: false, files: [] };
    }
  };

  const submit = async (): Promise<MultiUploadSubmitResult> => {
    const _instancesKeys = Object.keys(instances);
    const instancesResult: InstanceResultMap = {};

    for await (const instanceKey of _instancesKeys) {
      const instance = instances[instanceKey];
      const { files } = instance;
      const idleFiles = files.filter((f) => f.status === 'idle');

      if (idleFiles.length === 0) {
        instancesResult[instanceKey] = { success: true, files: [] };
        continue;
      }

      const { successful, failed } = await _uploadToS3(idleFiles, instanceKey);

      if (_options?.enableProcessAndCreate && successful.length > 0) {
        const result = await _processAndCreate(successful);

        instancesResult[instanceKey] = {
          success: result.success,
          files: result.files,
        };
      } else {
        instancesResult[instanceKey] = {
          success: failed.length === 0,
          files: successful,
        };
      }
    }

    const success = Object.keys(instancesResult).reduce((isSuccess, key) => {
      const result = instancesResult[key];
      return isSuccess && result.success;
    }, true);

    return { success, results: instancesResult };
  };

  return {
    instances,
    isLoading,
    hasError,
    filesCount,
    addInstances,
    removeInstances,
    addFile,
    removeFile,
    updateFile,
    submit,
    options: _options,
  };
};

type UseMultiUploadReturn = ReturnType<typeof useMultiUpload>;

// =============================================================
// Multi Upload Context

const MultiUploadContext = createContext<UseMultiUploadReturn | undefined>(
  undefined
);

export const useMultiUploadContext = () => {
  const ctx = useContext(MultiUploadContext);
  if (!ctx) throw new Error('Missing <MultiUpload.Root />');
  return ctx;
};

const Root = ({
  children,
  ...props
}: PropsWithChildren<UseMultiUploadReturn>) => (
  <MultiUploadContext.Provider value={props}>
    {children}
  </MultiUploadContext.Provider>
);

// =============================================================
// Drop

export type InstanceStatus = 'idle' | 'success';

export type InstanceProps = BoxTypes & {
  status?: InstanceStatus;
  disabled?: boolean;
  instance: string;
  view?: 'column' | 'row';
  title: string;
  subtitle?: string;
};

const Instance = ({
  status = 'idle',
  disabled,
  instance,
  view,
  title,
  subtitle,
  ...props
}: InstanceProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { instances, addFile, removeFile, options } = useMultiUploadContext();
  const { isMobile } = useScreenSize();
  const [parent] = useAutoAnimate();

  const foundInstance = instances[instance];
  const asCol = view === 'column' || isMobile;

  const onAddFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) addFile(file, instance);
      }
    }
  };

  const cta =
    foundInstance.files.length > 0 || status === 'success' ? (
      <Paragraph variant="text-small">Add another file</Paragraph>
    ) : (
      <Box gap="4px" justifyContent="center">
        <Paragraph variant="text-small" as="span" color="#72757D">
          Drag & drop or
        </Paragraph>
        <Paragraph variant="text-small" as="span" color="#1192E5">
          browse files
        </Paragraph>
      </Box>
    );

  const iconStylesMap: Record<InstanceStatus, Record<string, string>> = {
    idle: {
      color: '#F2F9FE',
      icon: 'cloudUpload' as IconTypeProp,
      iconColor: '#46B6FF',
    },
    success: {
      color: '#EFFAF6',
      icon: 'checkLight' as IconTypeProp,
      iconColor: '#259B71',
    },
  };
  const iconStyles = iconStylesMap[status];

  const events = disabled
    ? {}
    : {
        onClick: () => input.current?.click?.(),
        onDragOver: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(true);
        },
        onDragLeave: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
        },
        onDrop: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
          onAddFiles(e.dataTransfer.files);
        },
      };

  return (
    <Box
      {...props}
      {...events}
      p="16px"
      flexDirection="column"
      border="1px solid #EEF0F6"
      borderRadius="12px"
    >
      <input
        style={{ display: 'none' }}
        type="file"
        multiple
        accept={options?.accept?.join(', ') ?? ''}
        ref={input}
        onChange={(e) => onAddFiles(e.target.files)}
      />
      <Box
        mb={foundInstance.files.length > 0 ? '24px' : '0'}
        flexDirection={asCol ? 'column' : 'row'}
        gap="16px"
        alignItems="center"
        opacity={isDragging ? '.6' : '1'}
      >
        <Box
          width="44px"
          height="44px"
          flex="0 0 44px"
          borderRadius="50%"
          bgcolor={iconStyles['color']}
          center
        >
          <Icon
            type={iconStyles['icon'] as IconTypeProp}
            color={iconStyles['iconColor']}
            fill={iconStyles['iconColor']}
          />
        </Box>
        <Box flex="1" flexDirection="column" gap="4px">
          <Paragraph textAlign={asCol ? 'center' : 'left'} fontWeight="600">
            {title}
          </Paragraph>
          {subtitle && (
            <Paragraph
              textAlign={asCol ? 'center' : 'left'}
              variant="text-small"
              color="#55585E"
            >
              {subtitle}
            </Paragraph>
          )}
        </Box>
        {!asCol && <Box cursor="pointer">{cta}</Box>}
      </Box>

      <Box
        ref={parent}
        maxHeight="400px"
        overflowY="auto"
        flexDirection="column"
        gap="8px"
      >
        {foundInstance.files.map((f) => {
          const { id, file, errors } = f;
          const [fileName, fileExtension] = file.name.split('.');
          const hasErrors = errors.length > 0;

          return (
            <Box
              key={id}
              p="12px"
              flexDirection="column"
              gap="16px"
              border="1px solid"
              borderColor={hasErrors ? '#CA0D02' : '#EEF0F6'}
              borderRadius="12px"
              bgcolor="#F8F8FB"
            >
              <Box flex="1" justifyContent="space-between">
                <Box gap="8px">
                  {f.status === 'uploading' ? (
                    <Spinner md />
                  ) : (
                    <Icon
                      type={hasErrors ? 'exclamationCircleError' : 'fileLine'}
                    />
                  )}

                  <Box>
                    <Paragraph
                      variant="text-small"
                      maxWidth={{ xs: '200px', md: '400px' }}
                      truncate
                    >
                      {fileName}
                    </Paragraph>
                    <Paragraph variant="text-small">.{fileExtension}</Paragraph>
                  </Box>
                </Box>
                <Box
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(id, instance);

                    if (input.current) {
                      input.current.value = '';
                    }
                  }}
                >
                  <Icon type="closeCircle" fill="#C5C8CD" />
                </Box>
              </Box>
              {hasErrors && (
                <Paragraph variant="text-small" color="#CA0D02">
                  {errors[0]}
                </Paragraph>
              )}
            </Box>
          );
        })}
      </Box>

      {asCol && (
        <Box mt="24px" cursor="pointer" justifyContent="center">
          {cta}
        </Box>
      )}
    </Box>
  );
};

export const MultiUpload = {
  Root,
  Instance,
};

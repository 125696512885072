import { useFlags } from 'flagsmith/react';
import styled, { css } from 'styled-components';

import { PropsWithChildren } from 'react';

import { useRouter } from 'next/router';

import {
  Box,
  BoxTypes,
  Button,
  ButtonProps,
  FadeIn,
  HeaderLogo,
  Icon,
  Logo,
  MultiColorIcon,
  Paragraph,
  ProgressBar,
  useBrandTheme,
  useMultiStepContext,
} from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

import { useScreenSize } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../contexts/lead-submission';
import {
  STEPS,
  STEPS_TO_HIDE_HEADER,
  STEPS_TO_HIDE_SIDEBAR,
  STEP_SIDEBAR_IMAGE,
  Step,
} from '../../metadata/constants';

// ====================================================================
// Layout

function getMobileGridAreas(showSidebarOnMobile: boolean, hideHeader: boolean) {
  let areas = ['header', 'sidebar', 'content', 'footer'];

  if (hideHeader) {
    areas = areas.filter((area) => area !== 'header');
  }
  if (!showSidebarOnMobile) {
    areas = areas.filter((area) => area !== 'sidebar');
  }
  return areas.map((area) => `"${area}"`).join(' ');
}

export const LayoutStyles = styled(Box)<{
  showSidebarOnMobile: boolean;
  hideHeader?: boolean;
  hideSidebar?: boolean;
}>`
  ${({ showSidebarOnMobile, hideHeader, hideSidebar }) => css`
    height: 100vh;
    display: grid;
    grid-template-columns: ${hideSidebar ? '1fr' : 'max-content 1fr'};
    grid-template-rows: auto 1fr;
    grid-template-areas: ${hideSidebar
      ? `
      'content content'
      'content content'
      'footer footer'
      `
      : `
      'sidebar content'
      'sidebar content'
      'sidebar footer'
      `};

    .header {
      grid-area: header;
      ${hideHeader && 'display: none;'}
    }

    .sidebar {
      grid-area: sidebar;
      ${hideSidebar && 'display: none;'}
    }

    .content {
      grid-area: content;
    }

    .footer {
      grid-area: footer;
    }

    ${media.untilDesktop`
      grid-template-columns: 1fr;
      grid-template-areas: ${getMobileGridAreas(
        showSidebarOnMobile,
        hideHeader
      )};
    `}
  `}
`;

export const Layout = (props: BoxTypes) => {
  const { currentStep } = useMultiStepContext();

  const hideHeader = STEPS_TO_HIDE_HEADER.includes(currentStep as Step);
  const hideSidebar = STEPS_TO_HIDE_SIDEBAR.includes(currentStep as Step);

  return (
    <LayoutStyles
      showSidebarOnMobile={currentStep === STEPS.LEAD_INFORMATION}
      hideHeader={hideHeader}
      hideSidebar={hideSidebar}
      {...props}
    />
  );
};

// ====================================================================
// Flow Progress

const FlowProgress = () => {
  const {
    'equity-lead-submission-flow-phase-2': { enabled: isLeadSubmissionPhase2 },
  } = useFlags(['equity-lead-submission-flow-phase-2']);

  const { currentStepIndex } = useMultiStepContext();
  const theme = useBrandTheme();

  const primary = theme?.colors?.primary || '#46B6FF';
  // TODO: remove once Phase 2 is rolled out permanently
  const PHASE_1_TOTAL_STEPS = 14;
  const PHASE_2_TOTAL_STEPS = 24;
  const totalSteps = isLeadSubmissionPhase2
    ? PHASE_2_TOTAL_STEPS
    : PHASE_1_TOTAL_STEPS;
  const progress = Math.round(((currentStepIndex + 1) * 100) / totalSteps);

  return (
    <ProgressBar
      height="4px"
      color={primary}
      shadow="#eef0f6"
      borderRadius="0px"
      progress={progress}
    />
  );
};

// ====================================================================
// Flow Header

export const Header = () => {
  const { goBack, isFirstStep } = useMultiStepContext();

  const theme = useBrandTheme();
  const primary = theme?.colors?.primary || '#46B6FF';

  return (
    <Box
      className="header"
      display={{ xs: 'flex', md: 'none' }}
      flexDirection="column"
    >
      <Box p="16px" justifyContent="center" gap="4px" position="relative">
        {!isFirstStep && (
          <Icon
            type="angleLeft"
            onClick={goBack}
            position="absolute"
            top="16px"
            left="16px"
          />
        )}

        <Paragraph variant="text-small" fontWeight="bold">
          Buy Before You Sell
        </Paragraph>

        <Box
          alignItems="center"
          gap="4px"
          position="absolute"
          top="16px"
          right="16px"
          cursor="pointer"
        >
          <Paragraph variant="text-xsmall" fontWeight="bold" color={primary}>
            FAQs
          </Paragraph>
          <Icon type="externalLink" size={16} fill={primary} />
        </Box>
      </Box>

      <FlowProgress />
    </Box>
  );
};

// ====================================================================
// Result Header

export const ResultHeader = () => {
  const router = useRouter();
  const { onClearFlowError: clearFormError } = useLeadSubmissionContext();

  return (
    <Box
      p={{ xs: '16px', md: '24px 80px' }}
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #F2F9FE"
    >
      <Logo width={{ xs: '120px', md: '160px' }} variant="horizontal" />
      <Button
        onClick={() => {
          clearFormError();
          router.push('/bbys/new');
        }}
        size={{ xs: 'small', md: 'large' }}
      >
        Submit Client
      </Button>
    </Box>
  );
};

// ====================================================================
// Sidebar

export const Sidebar = () => {
  const { currentStep } = useMultiStepContext();
  const { isMobile, isTablet } = useScreenSize();

  const theme = useBrandTheme();
  const primary = theme?.colors?.primary || '#46b6ff';
  const secondary = theme?.colors?.secondary || '#F2F9FE';
  const isLeadInfoStep = currentStep === STEPS.LEAD_INFORMATION;

  const renderImage = () => {
    const imageName = STEP_SIDEBAR_IMAGE[currentStep];
    const Image = imageName ? MultiColorIcon[imageName] : undefined;

    if (!Image || isMobile || isTablet) return null;

    const imageProps = {
      colors: theme.colors || {},
      containerProps: { maxHeight: '320px' },
    };

    if (isLeadInfoStep) {
      return (
        <Box position="absolute" bottom="0" left="calc(50% - 180px)">
          <Image {...imageProps} />
        </Box>
      );
    }
    return (
      <FadeIn flexDirection="column" alignItems="center" gap="16px">
        <Image {...imageProps} />
        <Paragraph color="#c5c8cd">{currentStep.toUpperCase()}</Paragraph>
      </FadeIn>
    );
  };

  const renderLogo = () => {
    if (isMobile) return null;

    if (theme?.logoUrl) {
      return <Box as="img" src={theme.logoUrl} width="160px" />;
    }
    return (
      <HeaderLogo
        shouldUseTextLogo
        target="_blank"
        url="https://lender.homelight.com/lenders/general"
      />
    );
  };

  return (
    <Box
      className="sidebar"
      width={{ xs: '100%', md: '380px' }}
      p={{ xs: '32px 24px', md: '80px 20px' }}
      display={{ xs: isLeadInfoStep ? 'flex' : 'none', md: 'flex' }}
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      bgcolor={secondary}
    >
      <Box
        maxWidth={{ xs: '100%', md: '240px' }}
        mb={{ xs: '20px', md: '80px' }}
        flexDirection="column"
        gap={{ xs: '20px', md: '40px' }}
      >
        {renderLogo()}

        <Box flexDirection="column" gap="4px">
          <Paragraph variant="heading-3" color={primary}>
            Buy Before You Sell
          </Paragraph>
          <Paragraph variant="heading-3">Application</Paragraph>
          {isLeadInfoStep && (
            <Paragraph color="#72757d">
              Submit your client to get an Equity Unlock Estimation and start
              their approval process - No cost or commitment required.
            </Paragraph>
          )}
        </Box>
      </Box>
      {renderImage()}
    </Box>
  );
};

// ====================================================================
// Content

export const Content = ({ children, ...props }: BoxTypes) => {
  return (
    <Box
      className="content"
      p={{ xs: '16px', md: '80px 80px 0px 80px' }}
      flexDirection="column"
      alignItems="center"
      overflowY="auto"
      {...props}
    >
      {children}
    </Box>
  );
};

// ====================================================================
// Container

type ContainerProps = BoxTypes & {
  title?: string;
  alignTitle?: string;
  subtitle?: string;
  portalEnabled?: boolean;
  containerProps?: BoxTypes;
};

export const Container = ({
  children,
  title,
  alignTitle,
  subtitle,
  containerProps = {},
  portalEnabled,
  ...props
}: ContainerProps) => {
  const { isMobile } = useScreenSize();

  return (
    <FadeIn
      flex="1"
      width={{ xs: '100%', md: '600px' }}
      flexDirection="column"
      alignItems="flex-start"
      {...containerProps}
    >
      {title && (
        <Paragraph
          width="100%"
          mt={portalEnabled ? '56px' : '0'}
          mb={subtitle ? '12px' : '32px'}
          textAlign={alignTitle}
          variant={isMobile ? 'heading-3' : 'heading-2'}
        >
          {title}
        </Paragraph>
      )}
      {subtitle && (
        <Paragraph width="100%" mb="32px" color="#55585E">
          {subtitle}
        </Paragraph>
      )}
      <Box width="100%" flexDirection="column" flex="1" {...props}>
        {children}
      </Box>
    </FadeIn>
  );
};

// ====================================================================
// Footer

export const Footer = ({ children }: PropsWithChildren) => {
  const { isFirstStep } = useMultiStepContext();
  const { isDesktop } = useScreenSize();

  return (
    <Box
      className="footer"
      flexDirection="column"
      border={{ xs: '1px solid #eef0f6', md: 'none' }}
    >
      {isDesktop && <FlowProgress />}
      <Box
        p="24px"
        justifyContent={{
          xs: 'unset',
          md: isFirstStep ? 'flex-end' : 'space-between',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

// ====================================================================
// Buttons

type BtnProps = Omit<ButtonProps, 'children'>;

export const BackButton = (props: BtnProps) => {
  const { goBack } = useMultiStepContext();

  return (
    <Button
      display={{ xs: 'none', md: 'flex' }}
      color="tertiary"
      size="large"
      iconLeft={<Icon type="angleLeft" />}
      onClick={goBack}
      {...props}
    >
      Back
    </Button>
  );
};

export const NextButton = (props: BtnProps) => {
  const { isLoading } = useLeadSubmissionContext();
  const { currentStep } = useMultiStepContext();

  return (
    <Button
      size="large"
      flex={{ xs: '1', md: 'unset' }}
      isLoading={isLoading}
      {...props}
    >
      {currentStep === STEPS.REVIEW_AND_SUBMIT ? 'Submit' : 'Next'}
    </Button>
  );
};

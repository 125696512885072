import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useRouter } from 'next/router';

import {
  Button,
  ButtonProps,
  Icon,
  useMultiStepContext,
  useSnapshotContext,
} from '@hl-portals/ui';

import { useLocalStorage } from '@hl-portals/hooks';

import { CardRadio } from '../../../../../shared/components/card-radio';
import { UCQuestionnaireFormData } from '../../../metadata/forms';
import { QuestionnaireRouterQuery } from '../../../metadata/types';

type BtnProps = Omit<ButtonProps, 'children'>;

export const BackButton = (props: BtnProps) => {
  const { goBack } = useMultiStepContext();

  return (
    <Button
      type="button"
      display={{ xs: 'none', md: 'flex' }}
      color="tertiary"
      size="large"
      onClick={goBack}
      {...props}
    >
      <Icon type="angleLeft" />
      Back
    </Button>
  );
};

type NextButtonProps = {
  validateFields?: Array<keyof UCQuestionnaireFormData>;
  logEvent?: () => void;
  callback?: () => void;
  isAllowedToSkip?: boolean;
} & BtnProps;

export const NextButton = (props: NextButtonProps) => {
  const { update } = useSnapshotContext();
  const { goNext, steps, currentStepIndex } = useMultiStepContext();

  const { trigger, watch } = useFormContext();
  const { flow, leadId, ...formData } = watch();
  const router = useRouter();
  const { uuid } = router.query as QuestionnaireRouterQuery;
  const [_, set] = useLocalStorage(leadId);
  const { validateFields, isAllowedToSkip, logEvent, callback } = props;

  const handleClick = async () => {
    if (validateFields?.length > 0) {
      const isValid = await trigger(validateFields);

      if (isValid) {
        goNext();
      }
      return;
    }

    if (isAllowedToSkip) {
      callback();
      goNext();
      return;
    }
    goNext();
  };

  const nextStep = steps[currentStepIndex + 1];

  return (
    <Button
      type="button"
      size="large"
      flex={{ xs: '1', md: 'unset' }}
      onClick={() => {
        if (logEvent) logEvent();

        update({
          ...formData,
          leadId,
          submitted_step: nextStep,
        });
        set(uuid);
        handleClick();
      }}
      {...props}
    >
      Next
    </Button>
  );
};

export const SubmitButton = (props: BtnProps) => (
  <Button type="button" size="large" flex={{ xs: '1', md: 'unset' }} {...props}>
    Submit
  </Button>
);

export const SaveAndExitButton = () => {
  const router = useRouter();
  const { update } = useSnapshotContext();
  const { currentStep } = useMultiStepContext();
  const { watch } = useFormContext();
  const { flow, leadId, submitted_step, ...formData } = watch();
  const { uuid } = router.query as QuestionnaireRouterQuery;
  const [, set] = useLocalStorage(leadId);

  return (
    <Button
      size="xsmall"
      flex={{ xs: '1', md: 'unset' }}
      onClick={() => {
        update({
          ...formData,
          leadId,
          submitted_step: currentStep,
        });
        set(uuid);
        router.push(`/portal/transactions/${leadId}`);
        toast('Progress saved', { type: 'success', position: 'top-center' });
      }}
      color="save-and-exit"
      mt="10px"
      mb="10px"
    >
      Save & Exit
    </Button>
  );
};

export { CardRadio };

import axios from 'axios';

import { ReactElement } from 'react';

import { http } from '@hl-portals/libs/http';

import {
  FETCH_PRESIGNED_S3_LINK,
  PROCESS_AND_CREATE_FILE,
} from '@hl-portals/constants';

import { ERRORS } from './constants';
import {
  FileType,
  ProcessAndCreateOptions,
  UploadFile,
  UploadToS3Options,
} from './types';

// ==================================================================
// Validators

const validateFileType = (file: File, supportedTypes: string[]) => {
  const parts = file.name.split('.');
  const fileType = parts.length > 1 ? parts[parts.length - 1] : null;
  return !fileType ? false : supportedTypes.includes(fileType.toLowerCase());
};

const validateFileSize = (file: File, maxSize: number) => {
  return file.size <= maxSize;
};

const validateFileUniquess = (files: UploadFile[], file: File) => {
  if (files.length === 0) return true;

  return !files.some((f) => {
    return f.file.name === file.name && f.file.size === file.size;
  });
};

const validateMaxFiles = (filesLength: number, maxFiles: number) => {
  return filesLength <= maxFiles;
};

type ValidateFileOptions = {
  validate?: (file: File) => string | null;
  unique?: boolean;
  maxFileSize?: number;
  minFiles?: number;
  maxFiles?: number;
  accept?: FileType[];
};

export const validateFile = (
  file: File,
  files: UploadFile[],
  options?: ValidateFileOptions
) => {
  const { name } = file;
  const errors: Array<string | ReactElement> = [];

  if (options?.accept) {
    const hasValidType = options.accept
      ? validateFileType(file, options.accept)
      : true;
    if (!hasValidType) {
      errors.push(ERRORS.TYPE(options.accept));
      return errors;
    }
  }

  if (options?.maxFileSize) {
    const hasValidSize = options.maxFileSize
      ? validateFileSize(file, options.maxFileSize)
      : true;
    if (!hasValidSize) {
      errors.push(ERRORS.SIZE(options.maxFileSize));
      return errors;
    }
  }

  if (options?.maxFiles) {
    const hasValidMaxFilesLength = options.maxFiles
      ? validateMaxFiles(files.length, options.maxFiles)
      : true;
    if (!hasValidMaxFilesLength) {
      errors.push(name, ERRORS.MAX_LENGTH);
      return errors;
    }
  }

  if (options?.unique) {
    const hasValidUniqueness = options.unique
      ? validateFileUniquess(files, file)
      : true;
    if (!hasValidUniqueness) {
      errors.push(ERRORS.UNIQUENESS);
      return errors;
    }
  }

  if (options?.validate) {
    const error = options.validate(file);
    if (error) errors.push(error);
  }

  return errors;
};

// ==================================================================
// Process and Create Files

type ProcessAndCreateFilesResponse = {
  id: string;
  attributes: {
    name: string;
    created_at: string;
    updated_at: string;
  };
  type: string;
};

export async function processAndCreate(
  files: UploadFile[],
  options?: ProcessAndCreateOptions
) {
  return http.private<ProcessAndCreateFilesResponse>({
    url: PROCESS_AND_CREATE_FILE,
    method: 'POST',
    data: {
      include: 'file_versions',
      fields: {
        file_version: 'mime,fastly_url,storage_key',
      },
      attachable_id: options?.leadId,
      attachable_type: options?.leadId ? 'Lead' : null,
      category: options?.fileCategory,
      files: files.map(({ key, file }) => ({
        name: file.name,
        storage_key: key,
        file_type: options?.fileType,
      })),
      token: options?.token,
    },
  });
}

// ==================================================================
// Upload to S3

type PresignedLink = { url: string; key: string };
type PresignedLinkResponse = PresignedLink[];

export async function uploadToS3(file: UploadFile, options: UploadToS3Options) {
  const presignedLinkResponse = await http.public.post<PresignedLinkResponse>(
    FETCH_PRESIGNED_S3_LINK,
    {
      mime: file.file.type,
      filename: file.file.name,
      file_count: 1,
      uploaded_by: options?.uploaded_by,
      file_type: options?.fileType == 'image' ? 'media' : options?.fileType,
      source: options.source,
      attachable_id: options?.leadId,
      token: options?.token,
      document_type: options?.documentType,
    }
  );

  const { url, key } = presignedLinkResponse.data?.[0] || {};

  await axios.put(url, file.file, {
    headers: {
      'Content-Type': file.file.type,
    },
    onUploadProgress: (progressEvent: any) => {
      const progress =
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0);
      options?.onFileProgress?.(progress);
    },
  });

  return { key };
}

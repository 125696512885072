import { PropsWithChildren, createContext, useContext, useState } from 'react';

type StepCallback = () => string;

export const useMultiStepLinear = (initialStep: string) => {
  const [history, setHistory] = useState<string[]>([initialStep]);
  const [nextStepCallback, setNextStepCallback] = useState<
    StepCallback | undefined
  >();

  const currentStep = history[history.length - 1];
  const prevStep = history[history.length - 2];
  const isFirstStep = prevStep === undefined;

  const registerNextStep = (callback: StepCallback) => {
    setNextStepCallback(() => callback);
  };

  const goTo = (step: string) => {
    setHistory((prev) => [...prev, step]);
  };

  const goNext = () => {
    if (!nextStepCallback) {
      console.warn('Step callback not available');
      return;
    }

    const nextStep = nextStepCallback();

    if (!nextStep) {
      console.warn('Next step not available');
      return;
    }
    setHistory((prev) => [...prev, nextStep]);
  };

  const goBack = () => {
    setHistory((prev) => prev.slice(0, -1));
  };

  return {
    history,
    setHistory,
    currentStep,
    prevStep,
    isFirstStep,
    registerNextStep,
    goTo,
    goNext,
    goBack,
  };
};

type UseMultiStepLinearReturn = ReturnType<typeof useMultiStepLinear>;

const MultiStepContext = createContext<UseMultiStepLinearReturn | null>(null);

export const useMultiStepLinearContext = () => {
  const ctx = useContext(MultiStepContext);
  if (!ctx) throw new Error('Missing <MultiStep.Root />');
  return ctx;
};

const Root = ({
  children,
  ...props
}: PropsWithChildren<UseMultiStepLinearReturn>) => (
  <MultiStepContext.Provider value={props}>
    {children}
  </MultiStepContext.Provider>
);

const Step = ({ children, name }: PropsWithChildren<{ name: string }>) => {
  const { currentStep } = useMultiStepLinearContext();

  if (currentStep === name) return <>{children}</>;
  return <></>;
};

export const MultiStepLinear = {
  Root,
  Step,
};

import { AxiosError } from 'axios';

import { UseMutationOptions, useMutation } from 'react-query';

import { http } from '@hl-portals/libs/http';
import { SnapshotAttributes, SnapshotResponse } from '@hl-portals/libs/types';

import { CREATE_SNAPSHOT_PATH } from '@hl-portals/constants';

import { handleErrors } from '@hl-portals/helpers';

export type SnapshotType = 'LenderLeadSnapshot';

export function useCreateSnapshot<Payload, ResponseData>(
  options?: UseMutationOptions<
    SnapshotAttributes<ResponseData>,
    unknown,
    Payload
  >
) {
  return useMutation(
    async (payload: Payload, type: SnapshotType = 'LenderLeadSnapshot') => {
      const res = await http.public.post<SnapshotResponse<ResponseData>>(
        CREATE_SNAPSHOT_PATH,
        {
          type,
          payload,
        }
      );
      return res.data?.data?.attributes;
    },
    {
      ...options,
      onError: (...args) => {
        options?.onError?.(...args);
        const error = args[0] as AxiosError;
        // Don't show error messages for aborted/canceled requests
        if (error.code !== 'ERR_CANCELED' && error.code !== 'ECONNABORTED') {
          handleErrors(error);
        }
      },
    }
  );
}

import * as Sentry from '@sentry/nextjs';

import {
  DragEvent,
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { useBrowserEvent } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import Spinner from '../Spinner';
import { Paragraph } from '../Typography';
import { ERRORS } from './constants';
import * as helpers from './helpers';
import { UploadFile, UploadOptions } from './types';

export const useUpload = (options: UploadOptions) => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const _options: UploadOptions = {
    accept: ['pdf', 'doc', 'docx', 'xml', 'jpeg', 'jpg', 'png', 'heic'],
    unique: true,
    enableProcessAndCreate: false,
    ...options,
  };

  const add = (file: File) => {
    const timestamp = Date.now();
    const errors = helpers.validateFile(file, files, _options);
    const status = errors.length > 0 ? 'error' : 'idle';

    setFiles((_files) => [
      ..._files,
      {
        id: `${file.name}-${timestamp}`,
        file,
        status,
        key: null,
        progress: 0,
        errors,
      },
    ]);
  };

  const remove = (id: string) => {
    setFiles((_files) => _files.filter((file) => file.id !== id));
    toast('Document removed', {
      type: 'success',
      position: 'top-right',
      autoClose: 3000,
    });
  };

  const update = (id: string, payload: Partial<UploadFile>) => {
    setFiles((prev) =>
      prev.map((f) => {
        if (f.id === id) return { ...f, ...payload };
        return f;
      })
    );
  };

  const setError = (id: string, errorKey: keyof typeof ERRORS) => {
    setFiles((prev) => {
      return prev.map((f) => {
        let errorMessage: string | ReactElement = '';

        switch (errorKey) {
          case 'INTERNAL':
            errorMessage = ERRORS.INTERNAL;
            break;
          case 'MAX_LENGTH':
            errorMessage = ERRORS.MAX_LENGTH;
            break;
          case 'SIZE':
            errorMessage = ERRORS.SIZE(options?.maxFileSize);
            break;
          case 'TYPE':
            errorMessage = ERRORS.TYPE(options?.accept);
            break;
          case 'UNIQUENESS':
            errorMessage = ERRORS.UNIQUENESS;
        }

        if (f.id === id) {
          return {
            ...f,
            status: 'error',
            errors: [...f.errors, errorMessage],
          };
        }
        return f;
      });
    });
  };

  const clearError = (id: string) => {
    update(id, { status: 'idle', errors: [] });
  };

  const uploadToS3 = async (_files: UploadFile[]) => {
    const successful = [];
    const failed = [];

    for await (const file of _files) {
      const { id, errors } = file;

      try {
        if (!errors?.length) {
          update(id, { status: 'uploading' });

          const { key } = await helpers.uploadToS3(file, {
            onFileProgress: (progress) => {
              update(id, { progress });
            },
            uploaded_by: _options?.uploaded_by,
            fileType: _options?.fileType,
            source: _options?.source,
            leadId: _options?.leadId,
            token: _options?.token,
            documentType: _options?.documentType,
          });

          update(id, { key, status: 'success' });
          successful.push({ ...file, key });
        }
      } catch (error) {
        Sentry.captureException(error);
        setError(id, 'INTERNAL');
        failed.push(file);
        toast('Upload incomplete. Please check your files and re-upload', {
          type: 'error',
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }
    return { successful, failed };
  };

  const processAndCreate = async (_files: UploadFile[]) => {
    try {
      await helpers.processAndCreate(_files, {
        leadId: _options.leadId,
        fileCategory: _options.fileCategory,
        fileType: _options.fileType,
        token: _options.token,
      });
      return { success: true };
    } catch (error) {
      Sentry.captureException(error);
      toast('There was an error', {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
      });
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async () => {
    const idleFiles = files.filter((f) => f.status === 'idle');

    if (idleFiles.length === 0) return { success: true };

    setIsLoading(true);
    const { successful, failed } = await uploadToS3(idleFiles);

    if (_options?.enableProcessAndCreate && successful.length > 0) {
      return processAndCreate(successful);
    }

    setIsLoading(false);
    return { success: failed.length === 0, files: successful };
  };

  useEffect(() => {
    if (options?.onFilesChange) {
      options.onFilesChange(files);
    }
  }, [files, options]);

  return {
    files,
    isLoading,
    add,
    remove,
    update,
    setError,
    clearError,
    submit,
  };
};

export type UseUploadReturn = ReturnType<typeof useUpload>;

// =============================================================
// Root

const UploadContext = createContext<UseUploadReturn | undefined>(undefined);

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error(
      'useUploadContext must be used within an Upload.Root component'
    );
  }
  return context;
};

type RootProps = PropsWithChildren<UseUploadReturn>;

const Root = (props: RootProps) => {
  return (
    <UploadContext.Provider value={props}>
      {props.children}
    </UploadContext.Provider>
  );
};

// =============================================================
// Drop

export type DropProps = BoxTypes & {
  disabled?: boolean;
  maxFileSize?: string; // in bytes
  accept?: string; // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
};

const Drop = (props: DropProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { add } = useUploadContext();

  useBrowserEvent('OPEN_FILE_PICKER', () => {
    input.current?.click?.();
  });

  const onAddFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) add(file);
      }
    }
  };

  const events = props.disabled
    ? {}
    : {
        onClick: () => input.current?.click?.(),
        onDragOver: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(true);
        },
        onDragLeave: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
        },
        onDrop: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
          onAddFiles(e.dataTransfer.files);
        },
      };

  return (
    <Box
      width="100%"
      flex="1"
      p="24px"
      borderRadius="12px"
      border="2px dashed"
      borderColor={isDragging ? '#46B6FF' : '#C5C8CD'}
      bgcolor={isDragging ? '#F2F8FE' : 'white'}
      transition="all 200ms ease-in-out"
      cursor={props.disabled ? 'not-allowed' : 'cursor'}
      opacity={props.disabled ? '.5' : '1'}
      justifyContent="center"
      {...events}
      {...props}
    >
      <input
        style={{ display: 'none' }}
        type="file"
        multiple
        accept={props.accept ?? ''}
        ref={input}
        onChange={(e) => onAddFiles(e.target.files)}
      />
      {props.children || (
        <Box flexDirection="column" alignItems="center" gap="20px">
          <Icon type="cloudUpload" />
          <Paragraph>Drag & drop or</Paragraph>
          <Paragraph color="#1192E5" cursor="pointer">
            Select files
          </Paragraph>
          {props.maxFileSize && (
            <Paragraph color="coolGray2">
              Max {props.maxFileSize} MB each
            </Paragraph>
          )}
        </Box>
      )}
    </Box>
  );
};

// =============================================================
// List

const List = (props: BoxTypes) => {
  const { files, remove } = useUploadContext();

  const renderIcon = (status: UploadFile['status']) => {
    switch (status) {
      case 'uploading':
        return <Spinner md />;
      case 'error':
        return <Icon type="exclamationCircleError" />;
      default:
        return <Icon type="file" />;
    }
  };

  const filesWithErrorsFirst = files.sort((a, b) => {
    if (a.status === 'error' && b.status !== 'error') return -1;
    if (a.status !== 'error' && b.status === 'error') return 1;
    return 0;
  });

  return (
    <Box flexDirection="column" gap="8px" {...props}>
      {filesWithErrorsFirst.map((file, i) => {
        const { id, file: innerFile, status, errors } = file;
        const { name: fileName, size } = innerFile;
        const [name, extension] = fileName.split('.');

        return (
          <Box
            key={`${id}-${i}`}
            p="16px"
            flexDirection="column"
            position="relative"
            border="1px solid"
            borderColor={status === 'error' ? '#F93A2F' : '#DBDFE6'}
            borderRadius="12px"
            overflowX="hidden"
            overflowY="hidden"
          >
            <Box flexDirection="column" gap="10px">
              <Box alignItems="center" gap="10px">
                <Box
                  width="24px"
                  height="24px"
                  justifyContent="center"
                  alignItems="center"
                >
                  {renderIcon(status)}
                </Box>
                <Paragraph variant="text-small">{name}</Paragraph>
              </Box>

              <Box justifyContent="space-between" gap="16px">
                {status === 'error' ? (
                  errors.map((e) => (
                    <Paragraph
                      variant="text-small"
                      key={file.id}
                      color="#F93A2F"
                    >
                      {e}
                    </Paragraph>
                  ))
                ) : (
                  <Paragraph variant="text-small" color="#72757D">
                    {(size / (1024 * 1024)).toFixed(2)} MB{' '}
                    {extension.toUpperCase()}
                  </Paragraph>
                )}

                {status !== 'success' && (
                  <Box
                    opacity=".1"
                    onClick={() => {
                      remove(id);
                    }}
                    cursor="pointer"
                  >
                    <Icon type="closeCircle" />
                  </Box>
                )}
              </Box>
            </Box>

            {status !== 'success' && (
              <Box
                width={file.progress + '%'}
                height="2px"
                position="absolute"
                bottom="0"
                left="0"
                bgcolor="#1192E5"
                transition="all 200ms ease-in-out"
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export const Upload = {
  Root,
  Drop,
  List,
};
